<template>
  <div class="flex items-center py-4 first:pt-0 last:pb-0 animate-pulse">
    <div
      class="flex items-center justify-center bg-gray-200 rounded-xl size-16"
    />
    <div class="flex-grow ml-3">
      <div class="w-12 h-6 bg-gray-200 rounded-md" />
      <div class="h-4 mt-1 bg-gray-200 rounded-md" />
    </div>
  </div>
</template>
