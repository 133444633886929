<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 1281.8 256"
  >
    <g>
      <path
        d="M72.8,206h48.5L194.4,5.8h-47.8l-49.6,136.1L47.8,5.5H0l72.8,200.5ZM297.5,211.4c50,0,89.8-30.8,103.9-73.8h-50c-9.4,20.3-29.3,31.5-53.2,31.5s-56.1-20.6-61.2-48.1h167.9c.9-4.8,1.4-9.6,1.5-14.5C406.5,46.4,358.7.5,297.5.5s-108.9,45.9-108.9,104.5,47.8,106.4,108.9,106.4h0ZM237.8,86.6c3.3-25.3,27.2-43.8,59-43.8s55.4,19.9,59.4,43.8h-118.4ZM428.5,256h45.6v-88.4h1.4c17.4,30,43.8,44.2,76.7,44.2,54.3,0,96.3-47.1,96.3-105.7S606.6.4,552.3.4s-65.5,18.1-77.8,46h-1.5V5.8h-44.5v250.2ZM540.4,169.1c-33.3,0-60.8-24.6-60.8-63s27.5-63,60.8-63,61.5,24.6,61.5,63-27.9,63-61.5,63ZM759.4,211.4c32.2,0,58.3-13,75.6-44.5h1.4v39.1h44.2V5.8h-44.2v39.8h-1.5c-12.7-26.4-40.2-45.7-75.7-45.7-56.8,0-98.8,47.1-98.8,105.7s42,105.7,98.8,105.7h0ZM768.8,168.7c-35.8,0-60.4-27.1-60.4-63s24.6-63,60.4-63,61.2,26.8,61.2,63c0,35.8-25,63-61.2,63h0ZM1001.9,211.4c32.2,0,58.3-13,75.7-44.5h1.4v39.1h44.2V5.8h-44.2v39.8h-1.4c-12.7-26.4-40.2-45.7-75.7-45.7-56.8,0-98.8,47.1-98.8,105.7s42,105.7,98.8,105.7h0ZM1011.3,168.7c-35.8,0-60.4-27.1-60.4-63s24.6-63,60.4-63,61.2,26.8,61.2,63c0,35.8-25,63-61.2,63h0ZM1161.1,206h45.6V48.2h74.9V5.8h-120.5v200.2ZM1276.3,174c-6.8-7.2-18.2-7.5-25.4-.6-.2.2-.4.4-.6.6-7.3,7.3-7.3,19.1,0,26.4,6.9,7.2,18.3,7.4,25.4.6.2-.2.4-.4.6-.6,7.2-7.3,7.2-19.1,0-26.5h0ZM1273.3,197.5c-5.4,5.5-14.2,5.6-19.7.3,0,0-.2-.2-.3-.3-5.4-5.8-5.4-14.8,0-20.6,5.4-5.5,14.3-5.6,19.8-.2,0,0,.1.1.2.2,5.4,5.8,5.4,14.8,0,20.6h0ZM1270.8,184.3c0-1.8-.7-3.5-2-4.6-1.4-1.2-3.3-1.8-5.1-1.7h-7.7v18.6h4.8v-5.9h2.4l4.5,5.9h5.3l-5.1-7c1.9-1,3.1-3.1,3.1-5.3h0ZM1263.6,182.1c1.1-.2,2.1.6,2.3,1.6,0,.2,0,.4,0,.7,0,.6-.2,1.2-.6,1.6-.5.4-1.1.6-1.7.6h-2.8v-4.4h2.8Z"
      />
    </g>
  </svg>
</template>
