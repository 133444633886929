<script setup>
import { Gift, Info, Crm } from "icons/icons.js";

const menuItems = [
  {
    to: "support",
    label: "Support",
    icon: Crm,
  },
  { to: "about", label: "About", icon: Info },
  { to: "more", label: "More", icon: Gift },
];
</script>

<template>
  <div
    class="grid w-full grid-cols-3 p-2 bg-white rounded-full shadow-xl shadow-primary-200"
  >
    <RouterLink
      :to="{ name: item.to }"
      active-class="text-white bg-primary-500 hover:text-white"
      class="flex items-center justify-center w-full h-12 space-x-2 text-gray-500 rounded-full hover:text-gray-800"
      v-for="(item, index) in menuItems"
      :key="index"
    >
      <Component class="size-6" :is="item.icon" />
      <span class="ml-1">{{ item.label }}</span>
    </RouterLink>
  </div>
</template>
