import gql from "graphql-tag";

export const ABOUT_GET = gql`
  query store($hash: String!) {
    store(hash: $hash) {
      name
      campaign {
        desc
        profilePicture {
          id
          url
        }
        socialLink {
          platform
          id
          url
          title
        }
      }
    }
  }
`;
