<template>
  <div
    class="flex flex-col items-center justify-center p-8 text-center bg-white shadow-lg rounded-3xl shadow-primary-100"
  >
    <!-- Person -->
    <div class="inline-block bg-gray-200 rounded-full size-20" />
    <div class="inline-flex w-24 h-6 mt-3 bg-gray-200 rounded-md" />
    <div class="inline-flex w-48 h-4 mt-1 bg-gray-200 rounded-md" />

    <!-- Action -->
    <div class="flex justify-center mt-4 space-x-2">
      <div class="w-32 h-12 bg-gray-200 rounded-full" />
      <div class="w-32 h-12 bg-gray-200 rounded-full" />
    </div>
  </div>
</template>
