import gql from "graphql-tag";

export const HYDRATION_GET = gql`
  query store($hash: String!) {
    store(hash: $hash) {
      theme {
        vepaarBranding
      }
    }
  }
`;
