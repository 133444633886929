import gql from "graphql-tag";

export const SUPPORT_GET = gql`
  query support($hash: String!) {
    store(hash: $hash) {
      mobiles {
        id
        profilePicture {
          id
          url
          size {
            _200x200
          }
        }
        name
        designation
        isAvailable
        isAvailableForWhatsappSupport
        isAvailableForCallSupport
        mobileNumber {
          numberWithCallingCode
          internationalFormat
        }
      }
    }
  }
`;
