import {
  siVimeo,
  siSkype,
  siQuora,
  siUpwork,
  siReddit,
  siTinder,
  siTumblr,
  siFlickr,
  siTiktok,
  siGithub,
  siTwitter,
  siBehance,
  siYoutube,
  siDiscord,
  siTelegram,
  siLinkedin,
  siFacebook,
  siSnapchat,
  siWhatsapp,
  siDribbble,
  siPinterest,
  siInstagram,
  siMessenger,
  siSoundcloud,
  siFoursquare,
} from "simple-icons";

export default {
  facebook: { color: "bg-blue-50 text-blue-500", icon: siFacebook },
  twitter: { color: "bg-info-50 text-info-500", icon: siTwitter },
  instagram: {
    color: "bg-warning-50 text-warning-500",
    icon: siInstagram,
  },
  snapchat: { color: "bg-lime-50 text-lime-500", icon: siSnapchat },
  youtube: { color: "bg-red-50 text-red-500", icon: siYoutube },
  linkedin: { color: "bg-blue-50 text-blue-500", icon: siLinkedin },
  tiktok: { color: "bg-pink-50 text-pink-500", icon: siTiktok },
  messenger: { color: "bg-blue-50 text-blue-500", icon: siMessenger },
  telegram: { color: "bg-info-50 text-info-500", icon: siTelegram },
  whatsapp: { color: "bg-green-50 text-whatsappGreen", icon: siWhatsapp },
  github: { color: "bg-gray-50 text-gray-800", icon: siGithub },
  dribbble: { color: "bg-pink-50 text-pink-500", icon: siDribbble },
  behance: { color: "bg-blue-50 text-blue-500", icon: siBehance },
  upwork: { color: "bg-lime-50 text-lime-500", icon: siUpwork },
  skype: { color: "bg-info-50 text-info-500", icon: siSkype },
  pinterest: { color: "bg-red-50 text-red-500", icon: siPinterest },
  reddit: { color: "bg-warning-50 text-warning-500", icon: siReddit },
  foursquare: {
    color: "bg-indigo-50 text-indigo-500",
    icon: siFoursquare,
  },
  tumblr: { color: "bg-gray-50 text-gray-500", icon: siTumblr },
  soundcloud: {
    color: "bg-warning-50 text-warning-500",
    icon: siSoundcloud,
  },
  flickr: { color: "bg-pink-50 text-pink-500", icon: siFlickr },
  quora: { color: "bg-red-50 text-red-500", icon: siQuora },
  discord: { color: "bg-indigo-50 text-indigo-500", icon: siDiscord },
  tinder: { color: "bg-pink-50 text-pink-500", icon: siTinder },
  vimeo: { color: "bg-info-50 text-info-500", icon: siVimeo },
};
