<script setup>
import TheFooter from "@/components/the-footer.vue";
import { HYDRATION_GET } from "./gql";
import { useRoute } from "vue-router";
import { query } from "plugins/apollo.js";
import { ref } from "vue";
import Logo from "@/components/vepaar-logo.vue";

const branding = ref(false);

const {
  params: { hash },
} = useRoute();

query(HYDRATION_GET, { hash }).then(({ data }) => {
  branding.value = data.store.theme.vepaarBranding;
});
</script>

<template>
  <div class="relative flex flex-col h-screen overflow-auto">
    <RouterView />

    <!-- Vepaar Branding -->
    <div class="py-6 text-center">
      <div class="flex flex-col items-center justify-center" v-if="branding">
        <p
          class="leading-none tracking-widest uppercase text-xxs text-primary-200"
        >
          Powered By
        </p>
        <a
          class="mt-1"
          :href="`https://vepaar.com/online-store?utm_source=chat&utm_medium=footer&utm_campaign=vepaar_branding`"
          target="_blank"
        >
          <Logo
            class="h-4 [&_path]:fill-primary-200 [&_path]:hover:fill-primary-500"
          />
        </a>
      </div>

      <div v-else class="tracking-widest uppercase text-primary-200 text-xxs">
        ー That’s a wrap! ー
      </div>
    </div>

    <div class="sticky bottom-0 flex-none p-3 mt-auto">
      <TheFooter />
    </div>
  </div>
</template>
