<script setup>
import { useRoute } from "vue-router";
import ApolloError from "@/components/apollo-error.vue";
import TheHeader from "@/components/the-header.vue";
import Contact from "./_contact.vue";
import ContactShimmer from "./_contact-shimmer.vue";
import { SUPPORT_GET } from "./gql.js";

const {
  params: { hash },
} = useRoute();
</script>

<template>
  <div>
    <TheHeader title="Hello there!" sub-title="How can we help you?" />

    <ApolloQuery class="px-5 -mt-12" :query="SUPPORT_GET" :variables="{ hash }">
      <template v-slot="{ result: { error, data }, isLoading }">
        <!-- Loading -->
        <div v-if="isLoading" class="space-y-4">
          <ContactShimmer />
        </div>

        <!-- Error -->
        <ApolloError v-else-if="error" :value="error" />

        <!-- Result -->
        <div v-else-if="data" class="space-y-4">
          <Contact
            :key="contact.id"
            v-for="contact in data.store.mobiles"
            :data="contact"
          />
        </div>

        <!-- No result -->
        <div v-else class="p-5 bg-white rounded-3xl">
          <p class="font-semibold">No Contacts Found!</p>
          <p class="text-sm text-gray-400">
            If you're the owner of this widget, kindly add "Contact Numbers" to
            shoe them here.
          </p>
        </div>
      </template>
    </ApolloQuery>
  </div>
</template>
