import { createRouter, createWebHashHistory } from "vue-router";
import Index from "@/pages/index.vue";
import Support from "@/pages/support/main.vue";
import More from "@/pages/more/main.vue";
import About from "@/pages/about/main.vue";

const routes = [
  {
    path: "/:hash",
    component: Index,
    redirect: { name: "support" },
    children: [
      {
        path: "support",
        name: "support",
        component: Support,
      },
      {
        path: "about",
        name: "about",
        component: About,
      },
      {
        path: "more",
        name: "more",
        component: More,
      },
    ],
  },
];

const history = createWebHashHistory();
const router = createRouter({
  history,
  routes,
});

export default router;
