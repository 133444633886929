<script setup>
import { useRoute } from "vue-router";
import ApolloError from "@/components/apollo-error.vue";
import SocialLink from "./_social.vue";
import TheHeader from "@/components/the-header.vue";
import { ABOUT_GET } from "./gql.js";

const {
  params: { hash },
} = useRoute();
</script>

<template>
  <div>
    <TheHeader title="About Us" subTitle="Curious to know more about us?" />

    <ApolloQuery class="px-5 -mt-12" :query="ABOUT_GET" :variables="{ hash }">
      <template v-slot="{ result: { error, data }, isLoading }">
        <!-- Loading -->
        <div v-if="isLoading" class="space-y-4">
          <div class="p-6 bg-white rounded-3xl">
            <div class="animate-pulse">
              <div class="bg-gray-200 rounded-lg size-24" />
              <div class="w-48 h-6 mt-3 bg-gray-200 rounded-md" />
              <div class="h-4 mt-1 bg-gray-200 rounded-md" />
              <div class="h-4 mt-1 bg-gray-200 rounded-md" />
              <div class="w-24 h-4 mt-1 bg-gray-200 rounded-md" />
            </div>
          </div>

          <!-- Social link -->
          <div class="p-6 bg-white rounded-3xl">
            <div class="flex flex-wrap items-center gap-2 animate-pulse">
              <div class="bg-gray-200 rounded-md size-12"></div>
              <div class="bg-gray-200 rounded-md size-12"></div>
              <div class="bg-gray-200 rounded-md size-12"></div>
              <div class="bg-gray-200 rounded-md size-12"></div>
            </div>
          </div>
        </div>

        <!-- Error -->
        <ApolloError v-else-if="error" :value="error" />

        <!-- Result -->
        <div v-else-if="data" class="space-y-4">
          <!-- Store details -->
          <div class="p-6 bg-white rounded-3xl">
            <img
              v-if="data.store.campaign.profilePicture?.url"
              :src="data.store.campaign.profilePicture.url"
              class="rounded-lg size-24"
            />
            <h3 class="mt-3 text-xl font-bold">
              {{ data.store.name }}
            </h3>
            <p class="mt-1 text-gray-400">
              {{ data.store.campaign.desc }}
            </p>
          </div>

          <!-- Social link -->
          <div
            class="p-6 bg-white rounded-3xl"
            v-if="data.store.campaign.socialLink"
          >
            <div class="flex flex-wrap items-center gap-2">
              <SocialLink
                v-for="icon in data.store.campaign.socialLink"
                :key="`social-${icon.id}`"
                :platform="icon.platform"
                :href="icon.url"
                :title="icon.title"
                target="_blank"
              />
            </div>
          </div>
        </div>

        <!-- No result -->
        <div v-else class="p-5 bg-white rounded-3xl">
          <p class="font-semibold">No Data Found!</p>
        </div>
      </template>
    </ApolloQuery>
  </div>
</template>
