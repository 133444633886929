import { createApp } from "vue";
import "./style.css";
import App from "./App.vue";
import router from "./router/index.js";

import { apolloClient } from "plugins/apollo";
import { createApolloProvider } from "@vue/apollo-option";
import VueApolloComponents from "@vue/apollo-components";

const apolloProvider = createApolloProvider({
  defaultClient: apolloClient,
});

const app = createApp(App);

app.use(apolloProvider);
app.use(router);
app.use(VueApolloComponents);
app.mount("#app");
