<script setup>
import { LINKS_GET } from "./gql.js";
import { Link } from "icons/icons.js";
import { useRoute } from "vue-router";

const {
  params: { hash },
} = useRoute();
</script>

<template>
  <ApolloQuery :query="LINKS_GET" :variables="{ hash }">
    <template v-slot="{ result: { data }, isLoading }">
      <div v-if="!isLoading" class="space-y-2">
        <a
          v-for="(link, index) in data.store.campaign.otherLink"
          :key="index"
          class="flex items-center p-4 bg-white rounded-3xl hover:ring-4 ring-primary-100 ring-inset"
          :href="link.url"
          target="_blank"
        >
          <div
            class="flex items-center justify-center bg-gray-200 rounded-xl size-16"
          >
            <Link class="text-gray-400 size-8" />
          </div>
          <div class="ml-3">
            <p class="font-semibold">{{ link.title }}</p>
            <p
              v-if="link.description"
              class="text-sm font-medium text-gray-500 line-clamp-1"
            >
              {{ link.description }}
            </p>
          </div>
        </a>
      </div>
    </template>
  </ApolloQuery>
</template>
