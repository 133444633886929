<script setup>
import { COUPONS_GET } from "./gql.js";
import { Coupon } from "icons/icons.js";
import Card from "./_card.vue";
import CardItemShimmer from "./_card-item-shimmer.vue";
import { useRoute } from "vue-router";
import ApolloError from "@/components/apollo-error.vue";

const {
  params: { hash },
} = useRoute();
</script>

<template>
  <Card title="Live Offers & Discounts">
    <template #default>
      <ApolloQuery :query="COUPONS_GET" :variables="{ hash }">
        <template v-slot="{ result: { error, data }, isLoading }">
          <!-- Loading -->
          <div v-if="isLoading" class="divide-y">
            <CardItemShimmer />
            <CardItemShimmer />
          </div>

          <!-- Error -->
          <ApolloError v-else-if="error" :value="error" />

          <!-- Result -->
          <div v-else-if="data" class="divide-y">
            <div
              v-for="(coupon, index) in data.coupons.data"
              :key="index"
              class="flex items-center py-4 first:pt-0 last:pb-0"
            >
              <div
                class="flex items-center justify-center bg-gray-200 rounded-xl size-16"
              >
                <Coupon class="text-gray-400 size-8" />
              </div>
              <div class="ml-3">
                <p class="text-base font-semibold">{{ coupon.code }}</p>
                <p
                  v-if="coupon.description"
                  class="text-sm font-medium text-gray-500 line-clamp-1"
                >
                  {{ coupon.description }}
                </p>
              </div>
            </div>
          </div>

          <!-- No result -->
          <div v-else class="p-5 bg-white rounded-3xl">
            <p class="font-semibold">No Coupons Found!</p>
            <p class="text-sm text-gray-400">Check back soon!</p>
          </div>
        </template>
      </ApolloQuery>
    </template>
  </Card>
</template>
