import gql from "graphql-tag";

export const COUPONS_GET = gql`
  query coupons($hash: String!) {
    coupons(hash: $hash) {
      data {
        code
        description
      }
    }
  }
`;

export const LINKS_GET = gql`
  query store($hash: String!) {
    store(hash: $hash) {
      name
      campaign {
        otherLink {
          id
          url
          title
          description
        }
      }
    }
  }
`;
